import { useContext, useEffect, useState } from "react"
import { CategoryProvider } from "context/CategoryContext"
import { LanguageContext, LanguageType } from "context/LanguageContext"
import { useAuth } from "lib/auth"
import { useRouter } from "next/router"
import ButtonCloseQiscus from "@components/shared-components/ButtonCloseQiscus"
import useButtonCloseQiscus from "lib/hooks/useButtonCloseQiscus"
import { cutString } from "lib/helpers"
import fetcher from "lib/fetcher"
import useSWR from "swr"
import BottomNavigation from "../BottomNavigation"
import Footer from "../Footer"
import Header from "../Header"
import DrawerDefault from "./DrawerDefault"

export type QiscusAppStorage = "hidden" | "visible" | null

interface AlertClassStartInHourProps {
  programName: string
  id: string
  type: string
}

export function AlertClassStartInHour({ programName, id, type }: AlertClassStartInHourProps) {
  const { useTranslation: t } = useContext(LanguageContext)
  const router = useRouter()
  return (
    <div className="w-full relative bg-yellow-100">
      <div className="container relative flex flex-col lg:flex-row items-center justify-center lg:p-1 lg:px-0 px-4 py-1 mx-auto">
        <p className="text-center">
          <span className="text-yellow-700">{t("class")}&nbsp;</span>
          <b className="text-yellow-700">{`${cutString(programName, 60)}`}&nbsp;</b>
          <span className="text-yellow-700">{t("will_be_starting_soon")}&nbsp;</span>
        </p>
        <b
          onClick={() => {
            router.push(`/my-classes/${id}`)
          }}
          role="button"
          tabIndex={0}
          className="text-yellow-700 cursor-pointer">
          {`${t("view")} ${t("class").toLowerCase()} ${t("here").toLowerCase()}`}
        </b>
      </div>
    </div>
  )
}

function MainLayouts({ children, handleLogout }) {
  const { handleChangeLanguage } = useContext(LanguageContext)
  const { navbarFixed } = children.type
  const { closeQiscusApp, isQiscusHidden } = useButtonCloseQiscus()
  const router = useRouter()
  const { userProfile } = useAuth()
  const [isNavbarFixed, setIsNavbarFixed] = useState<boolean>(false)
  const [checkUrl, setCheckUrl] = useState(null)

  const { data: checkClassOjt } = useSWR(checkUrl, fetcher)

  useEffect(() => {
    if (userProfile?.user_id) {
      setCheckUrl(`/api/my_classes/check_class_ojt?user_id=${userProfile?.user_id}`)
    }
  }, [userProfile])

  useEffect(() => {
    if (navbarFixed === false) {
      setIsNavbarFixed(false)
    } else {
      setIsNavbarFixed(true)
    }
  }, [children, navbarFixed])

  const checkisProgramDetail = () =>
    router.pathname !== "/register" &&
    router.pathname !== "/[category]/inhouse/[program]" &&
    router.pathname !== "/[category]/[type]/[program]/[slug]" &&
    !router.pathname.includes("checkout")

  const dataNavigationLoggedIn = [
    {
      url: "/overview",
      name: "Dashboard",
    },
  ]

  if (userProfile?.user_role === "ptc-user") {
    dataNavigationLoggedIn.push({
      url: "/my-classes",
      name: "My Classes",
    })
  }

  useEffect(() => {
    if (router?.query.lang) {
      let languageQuery:LanguageType = "EN"
      const languageValue = router?.query.lang.toString()

      if (languageValue.toUpperCase() === "EN") {
        languageQuery = "EN"
      }
      localStorage.setItem("language", languageQuery)
      setTimeout(() => handleChangeLanguage(languageQuery), 100)
    }
  }, [router.query.lang])

  return (
    <div className="relative min-h-screen">
      <style>
        {`
          ${
            isQiscusHidden
              ? "#qismo-widget {display:none} #qiscus-app-button-close { display:none }"
              : ""
          }
          .svelte-1xkyjie {
            display: none;
          }
        `}
      </style>
      <div>
        <CategoryProvider>
          <Header withoutBorded isFixed={isNavbarFixed} handleLogout={handleLogout} />
          <div>
            <DrawerDefault handleLogout={handleLogout} classOjt={checkClassOjt?.data.ojt_class_count.aggregate.count} />
          </div>
          {isNavbarFixed && <div className={`h-16 sm:h-32 xl:h-20 2xl:h-16 ${router.asPath === "/" ? "bg-primary" : "bg-white"}`} />}
          <ButtonCloseQiscus closeQiscusApp={closeQiscusApp} />
          <div className="min-h-screen">{children}</div>
          <Footer />
        </CategoryProvider>
      </div>

      <div className="block sm:hidden">{checkisProgramDetail() && <BottomNavigation />}</div>
    </div>
  )
}

export default MainLayouts
