import Header from "@components/Layouts/Header"
import Navigation from "@components/Layouts/Navigation"
import Link from "next/link"
import { useContext, useEffect, useRef, useState } from "react"
import Alert from "lib/components/Alert"
import Loading from "lib/components/Loading"
import { isDesktop } from "react-device-detect"
import router from "next/router"
import { LoadingContext } from "context/LoadingContext"
import { ErrorContext } from "context/ErrorContext"
import dynamic from "next/dynamic"
import useSWR from "swr"
import fetcher from "lib/fetcher"
import { useAuth } from "lib/auth"
import clientData from "./client-navigation.json"
import BottomNavigation from "../BottomNavigation"
import DrawerDefault from "../DefaultLayout/DrawerDefault"

const Image = dynamic(() => import("lib/components/Image"), {
  ssr: false,
})

function AdminLayout({ children, handleLogout }) {
  const sidebarRef = useRef(null)
  const { mainError, mainErrorMessage } = useContext(ErrorContext)
  const { loading } = useContext(LoadingContext)
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [openDrawer, setOpenDrawer] = useState(true)
  const [lockPanel, setLockPanel] = useState(true)
  const [checkUrl, setCheckUrl] = useState(null)
  const [menuData, setMenuData] = useState(clientData)

  const { data: checkClassOjt } = useSWR(checkUrl, fetcher)
  const { userProfile } = useAuth()

  useEffect(() => {
    const sidebarValue = localStorage.getItem("sidebar") || "show"
    if (sidebarValue === "show") {
      setOpenDrawer(true)
      setLockPanel(true)
    } else {
      setOpenDrawer(false)
      setLockPanel(false)
    }
  }, [])

  useEffect(() => {
    setIsMobile(!isDesktop)
  }, [])

  useEffect(() => {
    if (userProfile?.user_id) {
      setCheckUrl(`/api/my_classes/check_class_ojt?user_id=${userProfile?.user_id}`)
    }
  }, [userProfile])

  useEffect(() => {
    if (checkClassOjt?.data.ojt_class_count.aggregate.count > 0) {
      if (!clientData.some((listMenu) => listMenu.tourName === "training-management-services")) {
        const resultData = clientData.splice(2, 0, {
          "url": "#",
          "name": "Training Management Services",
          "icon": "training-organizer",
          "tourName": "training-management-services",
          "roles":["ptc-user"],
          "children": [
            {
              "name": "Assessment",
              "url": "/training-management-services/assessment",
            },
          ],
        })
        setMenuData(resultData)
      }
    }
  }, [checkClassOjt])

  const checkisProgramDetail = () =>
    router.pathname !== "/[category]/inhouse/[program]" &&
    router.pathname !== "/[category]/[type]/[program]/[slug]"

  return (
    <div className="relative min-h-screen flex">
      <style>
        {`
          #qismo-widget {
            display: none;
          }
          #qiscus-app-button-close {
            display: none;
          }
        `}
      </style>
      <div
        ref={sidebarRef}
        style={{
          "transition": "all 400ms ease-in-out",
        }}
        className={`${openDrawer ? "w-[275px]" : "w-[60px]"} border-r hidden sm:block overflow-y-scroll no-scrollbar`}
        onMouseOver={() => {
          setOpenDrawer(true)
        }} 
        onFocus={() => {
          setOpenDrawer(true)
        }}
        onMouseLeave={() => {
          const sidebarValue = localStorage.getItem("sidebar")
          if (sidebarValue === "show") {
            setOpenDrawer(true)
          } else {
            setOpenDrawer(false)
          }
        }}
      >
        <div className={`h-16 flex flex-row items-center justify-between ${openDrawer ? "ml-3 mr-3" : "ml-3"}`}>
          <div className="cursor-pointer">
            <Link href="/" legacyBehavior>
              <a className="flex gap-x-2">
                <Image
                  src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1721135315/icons/logo-only.png"
                  alt="Logo"
                  width={36}
                  height={36}
                />
                {openDrawer &&
                  <Image
                    src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1721374340/icons/logo-text-only.png"
                    alt="Logo"
                    width={120}
                    height={36}
                  />
                }
              </a>
            </Link>
          </div>
          {openDrawer &&
            <div onClick={() => {
              const valueSidebar = localStorage.getItem("sidebar")
              if (valueSidebar === "show") {
                localStorage.setItem("sidebar", "hide")
                setOpenDrawer(!openDrawer)
                setLockPanel(!lockPanel)
              } else {
                localStorage.setItem("sidebar", "show")
                setLockPanel(true)
              }
            }} role="button" tabIndex={0}>
              <Image 
                src={lockPanel ? "https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1721274368/icons/side-panel-lock.svg" : "https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1721134941/icons/side-panel.svg"}
                alt="Side Panel"
                width={20}
                height={20}
                />
            </div>
          }
        </div>
        <Navigation dataNavigation={clientData} iconOnly={openDrawer} />
      </div>
      <div className="w-full">
        <Header theme="light" withoutLogo handleLogout={handleLogout} isAdmin />
        <div>
          <DrawerDefault handleLogout={handleLogout} classOjt={checkClassOjt?.data?.ojt_class_count?.aggregate?.count} />
        </div>
        <div className="min-h-screen pb-32 sm:pb-0">
          {mainError && (
            <Alert
              title="Oops!"
              subtitle={mainErrorMessage || "Something went wrong, please refresh this page."}
              color="danger"
              withoutClose
            />
          )}
          {loading && <Loading />}
          <div className={loading || mainError ? "hidden" : "h-inherit sm:pb-32"}>{children}</div>
        </div>
      </div>
      {isMobile && checkisProgramDetail() && <BottomNavigation />}
    </div>
  )
}

export default AdminLayout
