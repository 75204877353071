import LanguageOption from "@components/Selector/Language/Option"
import { GetBadgeData } from "@graphql/homepage/get_badge_data"
import { GetCategoriesQuery } from "@graphql/program/getCategories"
import { DrawerContext } from "context/DrawerContext"
import fetcher from "lib/fetcher"
import { capitalize, checkStringContain, cutString } from "lib/helpers"
import { colorType } from "lib/interfaces"
import Button from "lib/components/Button"
import Dropdown, { IconColorType } from "lib/components/Dropdown"
import Icons from "lib/components/Icons"
import Text, { weightProps } from "lib/components/Text"
import CategorySelector from "lib/components/CategorySelector"
import clientData from "components/Layouts/AdminLayout/client-navigation.json"
import { LanguageContext } from "context/LanguageContext"
import { useAuth } from "lib/auth"
import { gtmEvent } from "lib/gtm"
import Image from "next/legacy/image"
import Link from "next/link"
import { useRouter } from "next/router"
import { useContext, useEffect, useMemo, useState } from "react"
import useSWR from "swr"
import consola from "consola"
import ServicesHeader from "@components/Sections/Homepage/ServicesHeader"
import ProgramHeader from "@components/Sections/Homepage/ProgramHeader"
import { GetClassStartInHourResponse } from "@graphql/class/classStartInHour"
import { isDesktop } from "react-device-detect"
import InputSearch from "lib/components/InputSearch"
import useQueryParams from "hooks/useQueryParams"
import { GetClassStartOngoingResponse } from "@graphql/class/classStartOngoing"
import JoinUsModal from "./joinModal"

export const routeHomepage = ["/login", "/register", "/business", "/become-trainer", "/referral", "/about", 
"/programs", "/trusted", "/certifications", "/blog", "/terms", "/trainer-form", "/provider-form", "/forgot-password"]

const routeDashboard = ["/overview", "/my-classes", "/orders", "/certifications", "/login", "/register", 
"/management"]

interface AlertClassStartInHourProps {
  programName: string
  id: string
  type: string
  showMarquee: boolean
}

export function AlertClassStartInHour({ programName, id, type, showMarquee }: AlertClassStartInHourProps) {
  const { useTranslation: t } = useContext(LanguageContext)
  const router = useRouter()
  return (
    <div className={`w-full relative bg-yellow-100 ${showMarquee ? "-mt-12 sm:-mt-8" : "mt-0"}`}>
      <div className="container relative flex flex-col lg:flex-row items-center justify-center lg:p-1 lg:px-0 px-0 py-1 mx-auto">
        <p className="text-center">
          <span className="text-yellow-700 text-[12px] sm:text-base">{t("class")}&nbsp;</span>
          <b className="text-yellow-700 text-[12px] sm:text-base">{`${cutString(programName, isDesktop ? 60 : 30)}`}&nbsp;</b>
          <span className="text-yellow-700 text-[12px] sm:text-base">{t("will_be_starting_soon")}.&nbsp;</span>
        </p>
        <b
          onClick={() => {
            router.push(`/my-classes/${id}`)
          }}
          role="button"
          tabIndex={0}
          className="text-yellow-700 cursor-pointer text-[12px] sm:text-base">
          {t("click_here")}
        </b>
      </div>
    </div>
  )
}

export function AlertClassOngoing({ programName, id, type, showMarquee }: AlertClassStartInHourProps) {
  const { useTranslation: t } = useContext(LanguageContext)
  const router = useRouter()
  return (
    <div className={`w-full relative bg-yellow-100 ${showMarquee ? "-mt-12 sm:-mt-8" : "mt-0"}`}>
      <div className="container relative flex flex-col lg:flex-row items-center justify-center lg:p-1 lg:px-0 px-0 py-1 mx-auto">
        <p className="text-center">
          <span className="text-yellow-700 text-[12px] sm:text-base">{t("class")}&nbsp;</span>
          <b className="text-yellow-700 text-[12px] sm:text-base">{`${cutString(programName, isDesktop ? 60 : 30)}`}&nbsp;</b>
          <span className="text-yellow-700 text-[12px] sm:text-base">{t("is_ongoing")}.&nbsp;</span>
        </p>
        <b
          onClick={() => {
            router.push(`/my-classes/${id}`)
          }}
          role="button"
          tabIndex={0}
          className="text-yellow-700 cursor-pointer text-[12px] sm:text-base">
          {t("click_here")}
        </b>
      </div>
    </div>
  )
}

interface AlertUserVerificationProps {
  handleVerifyAccount: () => void
  loadingVerify: boolean
  showMarquee: boolean
}

export function AlertUserVerification({
  handleVerifyAccount,
  loadingVerify,
  showMarquee,
}: AlertUserVerificationProps) {
  const { useTranslation: t } = useContext(LanguageContext)
  const router = useRouter()
  return (
    <div className={showMarquee ? "-mt-12 sm:-mt-8" : "mt-0"}>
      <div className="w-full bg-yellow-100 text-center">
        <div className="sm:container relative flex justify-center lg:p-1 lg:px-2 px-4 py-2 mx-auto">
          <p className="text-sm sm:text-base">
            <b className="text-yellow-700">{t("your_account_is_not_verified")}&nbsp;</b>
            <span className="text-yellow-700">{t("you_cannot_use_all_features")}&nbsp;</span>
            <b
              role="button"
              tabIndex={0}
              onClick={() => router.push("/verification-method")}
              className="text-yellow-700 cursor-pointer z-20">
              {loadingVerify ? t("processing_verification") : t("verify_now")}
            </b>
          </p>
        </div>
      </div>
    </div>
  )
}

interface HeaderProps {
  theme?: "default" | "light" | "transparent"
  withoutBorded?: boolean
  withoutLogo?: boolean
  isFixed?: boolean
  handleLogout?: () => void
  isAdmin?: boolean
  isAbsolute?: boolean
  withSearchBar?: boolean
}

interface BadgeProfileProps {
  textColor: colorType
  firstName: string
  lastName: string
}


interface BulletRedProps {
  count: number
  withNumberCount?: boolean
}

declare const window: {
  dispatchEvent(storageEvent: Event): unknown
  QismoApp
  location
  addEventListener
  removeEventListener
  scrollY
}

const isDashboard = (value) => clientData.find((item) => item.tourName.indexOf(value) !== -1)

function BulletRed({ count = 0, withNumberCount = false }: BulletRedProps) {
  if (count > 0) {
    if (withNumberCount) {
      return (
        <Text
          alignText="center"
          size="extra-small"
          weight="bold"
          color="light"
          customClass="bg-red-500 w-6 h-6 rounded-full flex items-center justify-center">
          {count}
        </Text>
      )
    }
    return (
      <div className="inline-flex absolute -top-0 -right-1 justify-center items-center w-2.5 h-2.5 text-xs font-bold text-white bg-red-500 rounded-full" />
    )
  }

  return <div className="hidden" />
}

function NavigationItem({ color, weight, path, dataBadge }) {
  const { useTranslation: t } = useContext(LanguageContext)

  const newPath = path.substring(1).split("/")
  const myClasses = t("my_classes")
  const dataNavItem = [
    {
      url: "/my-classes",
      text: myClasses,
      hadBadge: dataBadge?.data?.class_count?.aggregate?.count > 0,
    },
  ]

  const checkDashboard = isDashboard(newPath[0])

  return (
    <>
      {dataNavItem.map((navItem, indexItem) => {
        let target = "_self"
        if (navItem.text === t("dashboard")) {
          target = checkDashboard ? "_self" : "_blank"
        }
        return (
          <div key={indexItem} className="relative">
            <a
              href={navItem.url}
              key={indexItem}
              target={target}
              rel="noreferrer"
              onClick={() => gtmEvent("ce_header_menu_click", { menu_name: navItem.text })}>
              <Text color={color} weight={weight} customClass="cursor-pointer">
                {navItem.text}
              </Text>
            </a>
            {navItem.hadBadge && (
              <div className="inline-flex absolute -top-0.5 -right-1.5 justify-center items-center w-2.5 h-2.5 text-xs font-bold text-white bg-red-500 rounded-full" />
            )}
          </div>
        )
      })}
    </>
  )
}

function BadgeProfile({ firstName, lastName, textColor }: BadgeProfileProps) {
  let bgBadge = "bg-blue-900"
  let sliceName = `${firstName?.slice(0, 1)}${lastName?.slice(0, 1)}`

  if (lastName === "." || lastName === "") {
    sliceName = firstName?.slice(0, 2)
  }

  if (textColor === "primary") {
    bgBadge = "bg-blue-200"
  }

  return (
    <div className={`flex justify-center items-center rounded-full w-10 h-10 ${bgBadge}`}>
      <Text color={textColor} weight="medium" alignText="center" customClass="uppercase">
        {sliceName}
      </Text>
    </div>
  )
}
function Header({
  theme,
  withoutBorded,
  withoutLogo,
  isFixed = false,
  handleLogout,
  isAdmin = false,
  isAbsolute = false,
  withSearchBar = false,
}: HeaderProps) {
  const router = useRouter()
  const queries = useQueryParams()
  const { userProfile, isLoggedIn, handleUserVerify } = useAuth()
  const { toggleDrawer } = useContext(DrawerContext)
  const { useTranslation: t, language } = useContext(LanguageContext)
  const [badgeDataUrl, setBadgeDataUrl] = useState(null)
  const [showModalJoin, setShowModalJoin] = useState(false)
  const [newMessages, setNewMessages] = useState([])
  const { data: dataCategory } = useSWR<GetCategoriesQuery>("/api/categories", fetcher)
  const { data: dataBadge } = useSWR<GetBadgeData>(badgeDataUrl, fetcher)
  const [activeDropdown, setActiveDropdown] = useState(null)
  const [activeTheme, setActiveTheme] = useState(theme)
  const [hoveredButton, setHoveredButton] = useState("hover:bg-white hover:text-primary")
  const [showMarquee, setShowMarquee] = useState(false)
  const [loadingVerify, setLoadingVerify] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>("")
  const [checkUrl, setCheckUrl] = useState(null)

  const homePath = isLoggedIn ? "/homepage" : "/"
  const [classStartInHourUrl, setClassStartInHourUrl] = useState<string>("")
  const [classStartOngoingUrl, setClassStartOngoingUrl] = useState<string>("")

  const { data: dataClassStartInHour } = useSWR<GetClassStartInHourResponse>(
    classStartInHourUrl,
    fetcher,
  )

  const { data: dataClassStartOngoing } = useSWR<GetClassStartOngoingResponse>(
    classStartOngoingUrl,
    fetcher,
  )

  const { data: checkClassOjt } = useSWR(checkUrl, fetcher)

  useEffect(() => {
    if (userProfile?.session_id) {
      setClassStartInHourUrl(`/api/my_classes/class_start_in_hour?user_id=${userProfile?.user_id}`)
      setClassStartOngoingUrl(`/api/my_classes/class_start_ongoing?user_id=${userProfile?.user_id}`)
    }
  }, [userProfile])

  useMemo(() => {
    if (userProfile?.session_id) {
      setBadgeDataUrl(
        `/api/homepage/get_badge_data?user_id=${userProfile?.user_id}&user_role=${userProfile?.user_role}`,
      )
    }

    return () => {
      setBadgeDataUrl(null)
    }
  }, [userProfile])

  useEffect(() => {
    if (userProfile?.user_id) {
      setCheckUrl(`/api/my_classes/check_class_ojt?user_id=${userProfile?.user_id}`)
    }
  }, [userProfile])

  useEffect(() => {
    try {
      const newMessageQiscus = localStorage.getItem("newMessage")
      if (newMessageQiscus) {
        const messages = JSON.parse(localStorage.getItem("newMessage"))
        setNewMessages(messages)
      }
    } catch(e) {
      consola.log(e.message)
    }
  }, [])

  useEffect(() => {
    function handleNewMessage () {
      try {
        const newMessageQiscus = localStorage.getItem("newMessage")
        if (newMessageQiscus) {
          const messages = JSON.parse(localStorage.getItem("newMessage"))
          setNewMessages(messages)
        }
      } catch(e) {
        consola.log(e.message)
      }
    }

    window.addEventListener("storage", handleNewMessage)
    return () => {
      window.removeEventListener("storage", handleNewMessage)
    }
  }, [])

  const changeTheme = () => {
    if (router.pathname === "/" || router.pathname === "/inhouse") {
      const { scrollY } = window
      if (scrollY > 600) {
        setActiveTheme("light")
        setHoveredButton("hover:bg-primary hover:text-white")
      } else {
        setActiveTheme("default")
        setHoveredButton("hover:bg-white hover:text-primary")
      }
    }
  }

  const handleSearch = () => {
    if (searchValue) {
      queries.set("keyword", searchValue)
    }
    if (!searchValue) {
      queries.delete("keyword")
    }
    
    const pathname =
      searchValue ? `/programs/all?keyword=${searchValue}` : "/programs/all"

    return router.push(pathname)
  }

  const handleVerifyAccount = async () => {
    setLoadingVerify(true)
    try {
      await handleUserVerify()
    } catch (err) {
      throw new Error("Error User Verify")
    } finally {
      setLoadingVerify(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", changeTheme)

    return () => window.removeEventListener("scroll", changeTheme)
  }, [router.pathname])

  const routeDefault = ["/login", "/register"]

  if (router.pathname !== "/" && !checkStringContain(router.asPath, routeDefault)) {
    theme = "light"
  }

  if (router.pathname === "/inhouse") {
    theme = "default"
    withSearchBar = true
  }

  const categories = dataCategory ? dataCategory.program_category : []
  let backgroundColor = "bg-primary"
  let iconColor = "text-white"
  let textColor: colorType = "light"
  let weight: weightProps = "normal"
  let dropDownIconColor: IconColorType = "white"
  let assetLogo = "/assets/images/logo-large-secondary.png"
  let marginXHeader = "px-4 sm:px-0 sm:mx-auto"
  let headerRightMargin = "pr-0"

  if (theme === "light" || activeTheme === "light") {
    backgroundColor = `bg-white ${withoutBorded ? "" : "shadow-bottom"}`
    assetLogo = "/assets/images/logo-large-primary.png"
    iconColor = "text-default"
    textColor = "primary"
    dropDownIconColor = "primary"
    weight = "medium"
    marginXHeader = "px-4 xl:px-0 2xl:px-0"
  }

  if (theme === "transparent" || activeTheme === "transparent") {
    backgroundColor = `bg-transparent ${withoutBorded ? "" : "shadow-bottom"}`
    assetLogo = "/assets/images/logo-large-secondary.png"
    iconColor = "text-default"
    textColor = "light"
    dropDownIconColor = "white"
    weight = "medium"
    marginXHeader = "px-4 xl:px-0 2xl:px-0"
  }

  if (isAdmin) {
    headerRightMargin = "pr-0"
  }

  return (
    <div>
      <div
        className={`w-full z-20 ${backgroundColor} ${showMarquee ? "h-36 sm:h-40 xl:h-36 2xl:h-32" : "h-16 sm:h-32 xl:h-20 2xl:h-16"} ${
          isFixed ? "fixed" : ""
        } ${isAbsolute ? "absolute" : ""}
        `}>

        <div
          className={`relative ${showMarquee ? "-mt-10 sm:-mt-8" : ""} ${marginXHeader} ${
            !isAdmin ? "sm:container" : ""
          } flex gap-x-6 h-full items-center justify-between mx-auto ${headerRightMargin}`}>
          <div className="block sm:hidden" />
          <div className={`flex gap-0 ${!isAdmin ? "sm:gap-x-10" : "sm:gap-x-8"} items-center`}>
            <div className="cursor-pointer h-full">
              <div
                tabIndex={0}
                role="button"
                className={`${withoutLogo ? "block sm:hidden h-inherit" : "block h-inherit"}`}
                onClick={() => {
                  localStorage.setItem("active_route", "home")
                  router.push("/")
                }}>
                <Link href="/" legacyBehavior>
                  <a className="flex items-center h-inherit">
                    <Image src={assetLogo} alt="Picture of the author" width={148} height={33} />
                  </a>
                </Link>
              </div>
            </div>
          </div>
          <div className={`${iconColor} w-10 relative sm:hidden`}>
            <Icons
              onClick={toggleDrawer}
              iconName="menu"
              color={isAbsolute ? "white" : "primary"}
            />
          </div>
          <div
            className={`hidden sm:flex flex-row items-center ${
              !isAdmin ? "gap-x-8" : "gap-x-8 mr-6"
            }`}>
            {withSearchBar && (
              <div className={language === "ID" ? "w-[22rem]" : "w-[18rem]"}>
                <InputSearch
                  name="search"
                  placeholder={t("what_do_you_want_to_learn")}
                  value={searchValue}
                  onChange={(event) => setSearchValue(event.target.value)}
                  roundedType="default"
                  onSearch={handleSearch}
                />
              </div>
            )}
            {isLoggedIn &&
              <div
                aria-label="home"
                role="button"
                tabIndex={0}
                onClick={() => gtmEvent("ce_header_menu_click", { menu_name: "Home" })}>
                <a
                  href={homePath}
                  className={textColor === "primary" ? "text-primary font-medium" : "text-white"}>
                  {t("home")}
                </a>
              </div>
            }
            <CategorySelector
              language={language}
              title={t("categories")}
              allCategory={t("all_categories")}
              topCategories={t("top_categories")}
              color={textColor}
              weight={weight}
              isHeader
              categories={categories}
              setActiveDropdown={setActiveDropdown}
              activeDropdown={activeDropdown}
              showMarquee={showMarquee}
            />
            <ProgramHeader
              title={t("programs")}
              color={textColor}
              weight={weight}
              isHeader
              setActiveDropdown={setActiveDropdown}
              activeDropdown={activeDropdown}
              showMarquee={showMarquee}
            />
            <ServicesHeader
              title={t("services")}
              color={textColor}
              weight={weight}
              isHeader
              setActiveDropdown={setActiveDropdown}
              activeDropdown={activeDropdown}
              showMarquee={showMarquee}
            />
            {!withSearchBar && (
              <NavigationItem
                color={textColor}
                weight={weight}
                path={router.asPath}
                dataBadge={dataBadge}
              />
            )}
            <LanguageOption colorTitleDropdown={textColor} />
            {isLoggedIn && userProfile && (
              <Dropdown
                withoutBackground
                fullWidth
                iconColor={dropDownIconColor}
                pathName={router.pathname}
                title={
                  <div className="relative">
                    <BadgeProfile
                      textColor={textColor}
                      firstName={userProfile.first_name}
                      lastName={userProfile.last_name}
                    />

                    <BulletRed
                      count={
                        dataBadge?.data.order_count?.aggregate.count ||
                        0 + (dataBadge?.data.proposal_count?.aggregate.count || 0) +
                        newMessages.length
                      }
                    />
                  </div>
                }>
                <ul className="list-none p-0 shadow-lg overflow-hidden rounded-lg absolute w-60 right-0 bg-white">
                  <li className="flex items-center gap-3 py-3 px-6 border-b hover:bg-gray-100">
                    <BadgeProfile
                      textColor={textColor}
                      firstName={userProfile.first_name}
                      lastName={userProfile.last_name}
                    />
                    <div>
                      <Text color="gray-dark" weight="medium" size="medium">
                        {cutString(
                          capitalize(`${userProfile?.first_name} ${userProfile?.last_name}`),
                          25,
                        )}
                      </Text>
                    </div>
                  </li>
                  {checkClassOjt?.data.ojt_class_count.aggregate.count > 0 && (
                    <Link href="/training-management-services/assessment">
                      <li className="py-2 px-6 hover:bg-gray-100">Evaluator Assignment</li>
                    </Link>
                  )}
                  <Link href="/orders">
                    <li className="relative py-2 px-6 hover:bg-gray-100 flex justify-between items-center">
                      {t("my_order")}
                      {userProfile.company_id && userProfile.user_role === "ptc-company-admin" ? (
                        <BulletRed withNumberCount count={dataBadge?.data.order_count.aggregate.count} />
                      ) : (
                        <BulletRed
                          withNumberCount
                          count={
                            dataBadge?.data.proposal_count?.aggregate.count ||
                            0 + (dataBadge?.data.order_count?.aggregate.count || 0)
                          }
                        />
                      )}
                    </li>
                  </Link>
                  <li className="py-2 px-6 hover:bg-gray-100 flex items-center justify-between" onClick={async () => {
                    await localStorage.removeItem("qiscusApp")
                    await localStorage.removeItem("newMessage")
                    setNewMessages([])

                    const element: HTMLElement = document.getElementsByClassName(
                      "qcw-trigger-btn",
                    )[0] as HTMLElement
                
                    element.click()
                  }}>
                    Chat
                    <BulletRed
                      withNumberCount
                      count={newMessages.length}
                    />
                  </li>
                  {userProfile.company_id && userProfile.user_role === "ptc-company-admin" && (
                    <Link href="/management/inhouse-proposal">
                      <li className="py-2 px-6 hover:bg-gray-100 flex items-center justify-between ">
                        {t("proposal")}
                        <BulletRed
                          withNumberCount
                          count={dataBadge?.data.proposal_count.aggregate.count}
                        />
                      </li>
                    </Link>
                  )}
                  <Link href="/settings">
                    <li className="py-2 px-6 hover:bg-gray-100">{t("settings")}</li>
                  </Link>
                  <Link href="/bookmark">
                    <li className="py-2 px-6 hover:bg-gray-100">{t("bookmark")}</li>
                  </Link>
                  <li className="hover:bg-gray-100">
                    <div
                      className="py-2 px-6"
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        handleLogout()
                      }}>
                      {t("logout")}
                    </div>
                  </li>
                </ul>
              </Dropdown>
            )}

            {!isLoggedIn && !userProfile && (
              <Button
                variant="outlined"
                color={theme !== "light" && activeTheme !== "light" ? "white" : "primary"}
                onClick={() => {
                  gtmEvent("ce_header_menu_click", { menu_name: "Login" })
                  if (router.asPath !== "/" && !checkStringContain(router.asPath, routeHomepage)) {
                    router.push(`/login?r=${encodeURIComponent(router.asPath)}`)
                  } else {
                    router.push("/login")
                  }
                }}>
                {t("login")}
              </Button>
            )}
          </div>
        </div>
        {showModalJoin && (
          <JoinUsModal
            onClose={() => {
              setShowModalJoin(false)
            }}
          />
        )}

        {isLoggedIn && userProfile && !userProfile?.is_verified && (
          <AlertUserVerification
            handleVerifyAccount={handleVerifyAccount}
            loadingVerify={loadingVerify}
            showMarquee={showMarquee}
          />
        )}

        {dataClassStartInHour?.data.class_class_will_start_in_hour.length > 0 && (
        <div className="mb-1">
          {!checkStringContain(router.asPath, routeDashboard) &&
            <AlertClassStartInHour
              showMarquee={showMarquee}
              programName={
                dataClassStartInHour.data.class_class_will_start_in_hour[0].program_name
              }
              id={dataClassStartInHour.data.class_class_will_start_in_hour[0].class_id}
              type={dataClassStartInHour.data.class_class_will_start_in_hour[0].type}
            />
          }
        </div>
        )}
        {dataClassStartOngoing?.data?.class_class_by_status.length > 0 && (
        <div className="mb-1">
          {!checkStringContain(router.asPath, routeDashboard) &&
            <AlertClassOngoing
              showMarquee={showMarquee}
              programName={
                dataClassStartOngoing.data.class_class_by_status[0].program.name
              }
              id={dataClassStartOngoing.data.class_class_by_status[0].id}
              type={dataClassStartOngoing.data.class_class_by_status[0].program.type}
            />
          }
        </div>
        )}
      </div>
      {(router.asPath !== "/" && !checkStringContain(router.asPath, routeDashboard) && dataClassStartInHour?.data.class_class_will_start_in_hour.length > 0) && (
        <div className="h-14 sm:h-16 lg:h-6 xl:h-6 2xl:h-8" />
      )}
      {(router.asPath !== "/" && !checkStringContain(router.asPath, routeDashboard) && dataClassStartOngoing?.data?.class_class_by_status.length > 0) && (
        <div className="h-14 sm:h-16 lg:h-6 xl:h-6 2xl:h-8" />
      )}
    </div>
  )
}

Header.defaultProps = {
  theme: "default",
  withoutBorded: false,
  withoutLogo: false,
}

export default Header